import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import firebase from 'firebase';
import { setUserSub } from 'redux/userSubInfo/userSub.slice';
import { API_URL } from 'utils/api-url.config';
import jwtDecode from 'jwt-decode';

export default function useAnanymousCheck() {
  const location = useLocation();
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const title = window?.platform_info.title;
  const token = localStorage.getItem(`access_token_r_${title}`);
  const couponTrue = localStorage.getItem(`coupon_login_${title}`);
  const refreshToken =
    !couponTrue && localStorage.getItem(`refresh_token_r_${title}`)
      ? localStorage.getItem(`refresh_token_r_${title}`)
      : '';

  const decodedAccessToken = token && jwtDecode(token);

  function makeRefreshAPICall() {
    fetch(`${API_URL}auth/token/refresh`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        token: JSON.parse(refreshToken)
      })
    })
      .then((res) => res.json())
      .then((res) => {
        const user = jwtDecode(res?.token);
        // console.log("Refresh token and set to localstorage", user)
        localStorage.setItem(`access_token_r_${title}`, JSON.stringify(res?.token));
        localStorage.setItem(`refresh_token_r_${title}`, JSON.stringify(res?.refreshToken));
        dispatch(setUserSub(user));
        // console.log('Letting know the application new subscription status');
      })
      .catch((error) => {
        console.log({ error });
      });
  }

  function observeDB(id) {
    // console.log('Trying to observe subscriptions db');
    const subscriptionsRef = firebase.database().ref('subscriptions').child(id);
    subscriptionsRef.on('value', (snapshot) => {
      const accessToken = localStorage.getItem(`access_token_r_${title}`);
      const localDecodedToken = accessToken && jwtDecode(accessToken);
      // console.log('here on subscriptions ref 1');
      if (snapshot.exists()) {
        const data = snapshot.val();

        // console.log(`device : ${localDecodedToken?.subscribe}`);
        // console.log(`firebase : ${data.data.subscribe}`);

        if (localDecodedToken?.subscribe != data.data.subscribe) {
          // console.log('Making refresh call 1.1');
          if (refreshToken) {
            // console.log('here on subscriptions ref 1.2');
            makeRefreshAPICall();
          }
        }
      } else {
        console.log('No SVOD data available');
      }
    });

    // Observe tvod_subscription db
    const tvodRef = firebase.database().ref('tvod_subscription').child(id);
    tvodRef.on('value', (snapshot) => {
      const accessToken = localStorage.getItem(`access_token_r_${title}`);
      const localDecodedToken = accessToken && jwtDecode(accessToken);
      // console.log('here on subscriptions ref 2');
      if (snapshot.exists()) {
        const data = snapshot.val();

        if (localDecodedToken?.isTVOD != data.data.tvod) {
          // console.log('here on subscriptions ref 2.1');
          if (refreshToken) {
            makeRefreshAPICall();
          }
        }
      } else {
        console.log('No TVOD data available');
      }
    });
  }

  useEffect(() => {
    // console.log('access tokens id', decodedAccessToken?.id);
    if (currentUser) {
      if (!couponTrue && decodedAccessToken?.id) {
        observeDB(decodedAccessToken?.id);
      }
    }
  }, [currentUser]);

  //   useEffect(() => {
  //     firebase.auth().onAuthStateChanged((user) => {
  //       if (user) {
  //         console.log('User in onAuthStateChange', user);
  //         // User is signed in, see docs for a list of available properties
  //         // https://firebase.google.com/docs/reference/js/auth.user
  //         const uid = user.uid;
  //         // console.log("Auth state observer", user)

  //         if (currentUser) {
  //           if (!couponTrue && decodedAccessToken?.id) {
  //             observeDB(decodedAccessToken?.id);
  //           }
  //         }

  //         // if (profileData?.id) {
  //         //observe firebase
  //         // observeDB(profileData?.id)
  //         // } else {
  //         // fetchData().then(res => {
  //         //     observeDB(res);
  //         // })
  //         // }
  //       } else {
  //         // User is signed out
  //         // ...
  //         // console.log("User signed out")
  //         firebase
  //           .auth()
  //           .signInAnonymously()
  //           .then((res) => {
  //             // Signed in..
  //             // console.log("Ananymous check signed in user", res)
  //           })
  //           .catch((error) => {
  //             const errorCode = error.code;
  //             const errorMessage = error.message;
  //             // ...
  //             console.log('Error anonymous sign in');
  //             if (errorCode === 'auth/operation-not-allowed') {
  //               console.log('You must enable Anonymous auth in the Firebase Console.');
  //             }
  //           });
  //       }
  //     });
  //   }, [location, currentUser]);
}
